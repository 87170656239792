
import styles from "./Background.module.css";

const style = {
  background: `url(bg.jpg) center/cover no-repeat rgba(0,0,0,0.68)`
}

export default function Background() {
  return <div className={styles.background} style={style}></div>
}
