import Main from "./components/Main/Main";
import Background from "./components/Background/Background";

function App() {
  return <>
    <Background />
    <Main />
  </>
}

export default App;
