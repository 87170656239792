import Button from "../Button/Button";
import styles from './Main.module.css';

export default function Main() {
  return <main className={styles.main}>

    <div className={styles.for_sale_container}>
      <span className={styles.for_sale}>Домен продаётся</span>
      <span className={styles.text_contact}>Свяжитесь с нами:</span>
      <div className={styles.buttons}>
        <Button text='e-mail' value='mailto:catforever@yandex.ru' icon='arroba.png' color='orange'/>
        <Button text='Telegram' value='https://t.me/catforevers' icon='telegram.png' color='#039be5'/>
      </div>
    </div>

    <div className={styles.hosting}>
      <a href="https://sweb.ru/?utm_term=kupuihcu" target='_blank' rel='noopener noreferrer'>
        <img src='sw.svg' alt=""/>
        <span>Надёжный хостинг сайтов</span>
      </a>
    </div>

  </main>
}